<template>
  <v-dialog width="500" v-model="dialog">
    <v-card width="500">
      <v-card-title v-html="isHindi ? (title_hi ? title_hi : title) : title">
        Are you sure?
      </v-card-title>
      <v-card-text
        v-if="description || description_hi"
        v-html="
          isHindi
            ? description_hi
              ? description_hi
              : description
            : description
        "
      />
      <v-divider class="mb-2" v-if="description || description_hi"></v-divider>
      <v-card-text
        class="limited-height"
        v-html="isHindi ? (text_hi ? text_hi : text) : text"
      >
        Choose an option below
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleCancel" :color="clrCancel ? clrCancel : ``">
          {{
            isHindi
              ? btnCancel_hi
                ? btnCancel_hi
                : btnCancel
                ? btnCancel
                : $lang("cancel")
              : btnCancel
              ? btnCancel
              : $lang("cancel")
          }}
        </v-btn>
        <v-btn
          text
          @click="handleOK"
          class="px-5"
          :color="clrOK ? clrOK : `red`"
        >
          {{
            isHindi
              ? btnOK_hi
                ? btnOK_hi
                : btnOK
                ? btnOK
                : $lang("ok")
              : btnOK
              ? btnOK
              : $lang("ok")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    "title",
    "title_hi",
    "text",
    "text_hi",
    "show",
    "btnOK",
    "btnOK_hi",
    "btnCancel",
    "btnCancel_hi",
    "clrOK",
    "clrCancel",
    "description",
    "description_hi",
  ],
  data() {
    return {
      dialog: false,
      isAgree: false,
    };
  },
  watch: {
    show(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit("changed", val);
    },
  },
  methods: {
    handleCancel() {
      this.$emit("result", false);
      this.dialog = false;
      console.log("result", "canceled");
    },
    handleOK() {
      this.$emit("result", true);
      this.dialog = false;
      console.log("result", "ok");
    },
  },
  mounted() {
    this.dialog = this.show;
  },
};
</script>

<style>
.limited-height {
  max-height: 50vh;
  overflow-y: scroll;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
</style>