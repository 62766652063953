<template>
  <v-card
    :class="!item.approved ? 'unapproved rounded-t-lg' : 'rounded-t-lg'"
    :id="getElementID"
    @click.stop="
      $router.push({
        name: 'feed-post',
        params: { id: item.id },
      })
    "
  >
    <v-card-title class="subtitle-1 px-0 mx-4">
      <v-badge
        v-tooltip="$lang('PENDINGAPPROVAL')"
        color="red"
        dot
        overlap
        offset-y="-14"
        v-if="!item.approved"
        style="cursor: pointer"
      ></v-badge>
      <v-avatar class="mr-2" size="42">
        <v-img
          @click.stop="
            $router.push({
              name: 'profile',
              params: { id: item.user.username },
            })
          "
          :src="item.user ? item.user.avatar : ''"
          :key="item.user ? item.user.avatar : item.id"
          alt="avatar"
          class="image"
        />
      </v-avatar>
      <v-badge
        v-if="!['student', 'member'].includes(item.user.role)"
        overlap
        bordered
        bottom
        offset-x="25"
        offset-y="0"
        color="green accent-4 white--text"
      >
        <template v-slot:badge>
          <UserBadge :user="item.user" />
        </template>
      </v-badge>
      <div>
        <span
          @click.stop="
            $router.push({
              name: 'profile',
              params: { id: item.user.username },
            })
          "
          :class="isDark ? 'py-0 my-0 white--text' : 'py-0 my-0 black--text'"
        >
          {{ item.user ? item.user.display_name : "user" }}
        </span>
        <div class="caption ma-0 pa-0 mt-n2">
          <small>{{ item.created_at | fromNow }}</small>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!['auto', 'post', 'signup'].includes(item.type)"
        icon
        @click.stop="$emit('contextClicked', item)"
      >
        <v-icon>mdi-arrow-top-right</v-icon>
      </v-btn>
      <card-menu
        :item="item"
        @removed="(itm) => $emit('removed', itm)"
        @remove="(itm) => $emit('remove', itm)"
        @print="print()"
      />
    </v-card-title>
    <v-card-text :class="isDark ? 'white--text' : 'black--text'">
      <latex-html
        :style="
          item.title.length < 161 && !item.image_url
            ? 'font-size: 1.3rem; line-height: 1.8rem'
            : ''
        "
        hasClass="ma-0 pa-0"
        :html="item.title"
      />
    </v-card-text>
    <v-card-text v-if="item.image_url" class="pa-0 ma-0">
      <v-img contain :src="item.image_url"></v-img>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CardMenu from "./CardMenu";
import { downloadDataURL } from "@/helpers/utils";
import moment from "@/plugins/moment";
import { Device } from "@capacitor/device";
import LatexHtml from "@/components/LatexHtml.vue";
import UserBadge from "@/components/UserBadge.vue";

export default {
  components: { CardMenu, LatexHtml, UserBadge },
  props: ["item"],
  data() {
    return {
      info: null,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    getElementID() {
      return "card-" + this.item.id;
    },
  },
  methods: {
    // fromNow(strTime) {
    //   return moment(strTime).fromNow();
    // },
    async print() {
      const el = document.getElementById(this.getElementID);
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: "dataURL",
        useCORS: true,
        backgroundColor: "rgba(0,0,0,0)",
      };
      let output = await this.$html2canvas(el, options);
      // console.log("this.output", this.output);
      if (this.info && this.info.platform == "web") {
        downloadDataURL(output, this.item.title);
      } else {
        this.openTab(output);
      }
    },
    openTab(data) {
      var image = new Image();
      image.src = data;

      var w = window.open("");
      w.document.write(image.outerHTML);
    },
  },
  async mounted() {
    this.info = await Device.getInfo();
  },
};
</script>

<style>
.rounded-corner {
  border-radius: 0.4rem;
}
.unapproved {
  box-shadow: inset 0px 0px 0px 1px rgba(255, 0, 0, 1);
}
</style>