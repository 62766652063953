var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authUser && _vm.authUser.id)?_c('v-menu',{attrs:{"closeOnClick":true,"closeOnContentClick":false,"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","plain":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2971493274),model:{value:(_vm.menuDialog),callback:function ($$v) {_vm.menuDialog=$$v},expression:"menuDialog"}},[_c('v-sheet',[_c('v-list',{attrs:{"dense":"","nav":"","subheader":""}},_vm._l((_vm.menuItemsCommon),function(menu,i){return _c('v-list-item',{key:i,on:{"click":function($event){$event.stopPropagation();return (function () {
            menu.action();
            _vm.menuDialog = false;
          }).apply(null, arguments)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(menu.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(menu.title))])],1)}),1),_c('FeedActions',{attrs:{"feed":_vm.item},on:{"finished":function($event){_vm.menuDialog = false}}}),(_vm.item.user_id == _vm.authUser.id)?_c('v-list',{attrs:{"dense":"","nav":"","subheader":""}},_vm._l((_vm.menuItems),function(menu,i){return _c('v-list-item',{key:i,on:{"click":function($event){$event.stopPropagation();return (function () {
            menu.action();
            _vm.menuDialog = false;
          }).apply(null, arguments)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(menu.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(menu.title))])],1)}),1):_vm._e(),_c('moderator-list-item',{staticClass:"mr-1",attrs:{"item":_vm.item,"uri":"feed"},on:{"removed":function (itm) {
          _vm.$emit('removed', itm);
          _vm.menuDialog = false;
        }}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }