<template>
  <v-menu
    v-if="authUser && authUser.id"
    :closeOnClick="true"
    :closeOnContentClick="false"
    bottom
    left
    v-model="menuDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" plain>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-sheet>
      <v-list dense nav subheader>
        <v-list-item
          v-for="(menu, i) in menuItemsCommon"
          :key="i"
          @click.stop="
            () => {
              menu.action();
              menuDialog = false;
            }
          "
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <FeedActions :feed="item" @finished="menuDialog = false" />

      <v-list dense nav subheader v-if="item.user_id == authUser.id">
        <v-list-item
          v-for="(menu, i) in menuItems"
          :key="i"
          @click.stop="
            () => {
              menu.action();
              menuDialog = false;
            }
          "
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <moderator-list-item
        :item="item"
        uri="feed"
        class="mr-1"
        @removed="
          (itm) => {
            $emit('removed', itm);
            menuDialog = false;
          }
        "
      />
    </v-sheet>
  </v-menu>
</template>

<script>
import ModeratorListItem from "./ModeratorListItem";
import { downloadURL } from "@/helpers/utils";
import { mapGetters } from "vuex";
import { Device } from "@capacitor/device";
import FeedActions from "@/app/ugcp/modules/FeedActions";

export default {
  props: ["item"],
  components: { ModeratorListItem, FeedActions },
  data() {
    return {
      menuDialog: false,
      closable: false,
      info: null,
      menuItemsCommon: [
        {
          icon: "mdi-download",
          title: "Download",
          action: () => {
            if (this.info && this.info.platform == "web") {
              this.$store.dispatch("setAlert", { message: "Downloading..." });
            } else {
              this.$store.dispatch("setAlert", { message: "Coming soon!" });
            }

            if (this.item.image_url) {
              this.download(this.item.image_url, this.item.title);
            } else {
              this.$emit("print");
            }
          },
        },
        {
          icon: "mdi-share-variant-outline",
          title: "Share",
          action: () => {
            this.$emit("share");
            this.$store.dispatch("setAlert", {
              message: "Coming soon!",
              type: "orange darken-4 white--text",
            });
          },
        },
      ],
      menuItems: [
        {
          icon: "mdi-close",
          title: "Remove",
          action: () => {
            this.$emit("remove", this.item);
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  methods: {
    async download(url, title) {
      downloadURL(url, title);
    },
  },
  async mounted() {
    this.info = await Device.getInfo();
  },
};
</script>

<style>
</style>