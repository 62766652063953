<template>
  <span v-tooltip="user.display_name">
    <v-icon v-if="['admin', 'superadmin'].includes(user.role)">
      mdi-crown-outline
    </v-icon>
    <v-icon v-if="['manager', 'moderator', 'teacher'].includes(user.role)">
      mdi-chess-queen
    </v-icon>
    <v-icon v-if="['student', 'member'].includes(user.role)">
      mdi-account
    </v-icon>
  </span>
</template>

<script>
export default {
  props: ["user"],
};
</script>

<style>
</style>