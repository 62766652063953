<template>
  <v-card class="rounded-b-lg" :style="getStyleColor" tile>
    <v-card-actions>
      <v-btn :loading="loading" @click.stop="handleHeart" text>
        <v-icon
          :color="
            heartedFeedIds && heartedFeedIds.includes(item.id) ? 'red' : ''
          "
          left
        >
          mdi-heart
        </v-icon>
        {{
          item.__meta__ && item.__meta__.hearts_count
            ? item.__meta__.hearts_count
            : 0
        }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-tooltip="$lang('TotalCommentsIncludingUnapproved')" text>
        <v-icon left small>mdi-comment</v-icon>
        {{ item.__meta__ ? item.__meta__.comments_count : 0 }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import bus from "@/bus";
import { axios } from "@/plugins/axios";
import VueTip from "@/components/VueTip";
import { stripScriptTags } from "@/helpers/utils";
import UploadArea from "@/components/UploadArea";
export default {
  props: ["item"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
      heartedFeedIds: "app/feed/heartedFeedIds",
    }),
    getStyleColor() {
      return this.$store.getters.isThemeDark
        ? "background-color: #2a2a2a"
        : "background-color: #efefef";
    },
  },
  methods: {
    handleHeart() {
      this.toggleHeart();
    },
    toggleHeart() {
      this.loading = true;
      return axios
        .post("reactions/toggle/hearted/feed/" + this.item.id)
        .then((res) => {
          // success
          if (res.data.resolved) {
            this.$store.dispatch("app/feed/patchHeartedFeedIds", {
              add: this.item.id,
            });
          } else {
            this.$store.dispatch("app/feed/patchHeartedFeedIds", {
              remove: this.item.id,
            });
          }
          this.item.__meta__.hearts_count = res.data.hearts_count;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>