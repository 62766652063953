var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:!_vm.item.approved ? 'unapproved rounded-t-lg' : 'rounded-t-lg',attrs:{"id":_vm.getElementID},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
      name: 'feed-post',
      params: { id: _vm.item.id },
    })}}},[_c('v-card-title',{staticClass:"subtitle-1 px-0 mx-4"},[(!_vm.item.approved)?_c('v-badge',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$lang('PENDINGAPPROVAL')),expression:"$lang('PENDINGAPPROVAL')"}],staticStyle:{"cursor":"pointer"},attrs:{"color":"red","dot":"","overlap":"","offset-y":"-14"}}):_vm._e(),_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"42"}},[_c('v-img',{key:_vm.item.user ? _vm.item.user.avatar : _vm.item.id,staticClass:"image",attrs:{"src":_vm.item.user ? _vm.item.user.avatar : '',"alt":"avatar"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
            name: 'profile',
            params: { id: _vm.item.user.username },
          })}}})],1),(!['student', 'member'].includes(_vm.item.user.role))?_c('v-badge',{attrs:{"overlap":"","bordered":"","bottom":"","offset-x":"25","offset-y":"0","color":"green accent-4 white--text"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('UserBadge',{attrs:{"user":_vm.item.user}})]},proxy:true}],null,false,2230731534)}):_vm._e(),_c('div',[_c('span',{class:_vm.isDark ? 'py-0 my-0 white--text' : 'py-0 my-0 black--text',on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
            name: 'profile',
            params: { id: _vm.item.user.username },
          })}}},[_vm._v(" "+_vm._s(_vm.item.user ? _vm.item.user.display_name : "user")+" ")]),_c('div',{staticClass:"caption ma-0 pa-0 mt-n2"},[_c('small',[_vm._v(_vm._s(_vm._f("fromNow")(_vm.item.created_at)))])])]),_c('v-spacer'),(!['auto', 'post', 'signup'].includes(_vm.item.type))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('contextClicked', _vm.item)}}},[_c('v-icon',[_vm._v("mdi-arrow-top-right")])],1):_vm._e(),_c('card-menu',{attrs:{"item":_vm.item},on:{"removed":function (itm) { return _vm.$emit('removed', itm); },"remove":function (itm) { return _vm.$emit('remove', itm); },"print":function($event){return _vm.print()}}})],1),_c('v-card-text',{class:_vm.isDark ? 'white--text' : 'black--text'},[_c('latex-html',{style:(_vm.item.title.length < 161 && !_vm.item.image_url
          ? 'font-size: 1.3rem; line-height: 1.8rem'
          : ''),attrs:{"hasClass":"ma-0 pa-0","html":_vm.item.title}})],1),(_vm.item.image_url)?_c('v-card-text',{staticClass:"pa-0 ma-0"},[_c('v-img',{attrs:{"contain":"","src":_vm.item.image_url}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }