"use strict";

export default [
  {
    reason: "inappropriate",
    title: "inappropriate username / profile picture",
    title_hi: "प्रोफाइल पिक्चर या यूजरनेम अनुपयुक्त है",
    dialog_title: "Report as inappropriate username / profile picture?",
    dialog_title_hi: "अनुपयुक्त यूजरनेम / प्रोफ़ाइल पिक्चर की रिपोर्ट करें?",
    dialog_text: `We remove:
                  <br/>- Inappropriate and offensive username / profile picture`,
    dialog_text_hi: `हम हटाते हैं:
                   <br/>- अनुपयुक्त और आपत्तिजनक यूजरनेम / प्रोफ़ाइल पिक्चर`,
    btnOK: "Report",
    btnOK_hi: "रिपोर्ट करें",
    btnCancel: "Back",
    btnCancel_hi: "वापस",
  },
  {
    reason: "impersonation",
    title: "Impersonation",
    title_hi: "प्रतिरूपण",
    dialog_title: "What can I do if someone is impersonating me on Vikarn?",
    dialog_title_hi:
      "अगर कोई मुझे विकर्ण पर प्रतिरूपित कर रहा है तो मैं क्या कर सकता हूं?",
    dialog_text: `Vikarn takes safety seriously. If someone created posts/comments/accounts pretending to be you, you can tap continue and fill out the form. Make sure to provide all the requested info, including a photo of your government-issued ID.
                  <br/><br/>We only respond to reports sent to us from the person who's being impersonated or a representative of the person who's being impersonated (ex: a parent). If someone you know is being impersonated, please encourage that person to report it.`,
    dialog_text_hi: `विकर्ण सुरक्षा को गंभीरता से लेता है। अगर किसी ने आपके होने का दिखावा करते हुए पोस्ट/टिप्पणियां/खाते बनाए हैं, तो आप जारी रखें पर टैप कर सकते हैं और फ़ॉर्म भर सकते हैं। अपनी सरकार द्वारा जारी आईडी की एक तस्वीर सहित सभी अनुरोधित जानकारी प्रदान करना सुनिश्चित करें।
                     <br/><br/>हम केवल उस व्यक्ति की ओर से भेजी गई रिपोर्ट का जवाब देते हैं जिसका प्रतिरूपण किया जा रहा है या उस व्यक्ति का प्रतिनिधि जिसे प्रतिरूपित किया जा रहा है (उदा: माता-पिता)। यदि आपके किसी परिचित का प्रतिरूपण किया जा रहा है, तो कृपया उस व्यक्ति को इसकी रिपोर्ट करने के लिए प्रोत्साहित करें।`,
    btnOK: "Continue",
    btnOK_hi: "जारी रखें...",
    btnCancel: "Back",
    btnCancel_hi: "वापस",
    url1: "https://vikarn.com/ugcp/report/impersonation",
    url: "/help-and-feedback",
  },
  {
    reason: "spam",
    title: "spam",
    title_hi: "स्पैम",
    dialog_title: "स्पैम की रिपोर्ट करें",
    dialog_title_hi: "",
    dialog_text: `- Clickbait
                  <br/>- Advertising
                  <br/>- Scam
                  <br/>- Script bot`,
    dialog_text_hi: `- क्लिकबैट
                    <br/>- विज्ञापन
                    <br/>- घोटाला
                    <br/>- स्क्रिप्ट बॉट`,
    btnOK: "Report",
    btnOK_hi: "रिपोर्ट करें",
    btnCancel: "Back",
    btnCancel_hi: "वापस",
  },
  {
    reason: "pornography",
    title: "pornography",
    title_hi: "पोर्नोग्राफी",
    dialog_title: "Report as pornography?",
    dialog_title_hi: "",
    dialog_text: `We remove:
                  <br/>- Photos or videos of sexual intercourse
                  <br/>- Contents showing sexual intercourse, genitals or close-ups of fully-nude buttocks`,
    dialog_text_hi: `हम हटाते हैं:
                    <br/>- संभोग की तस्वीरें या वीडियो
                    <br/>- संभोग, जननांगों या पूरी तरह से नग्न नितंबों के क्लोज-अप दिखाने वाली सामग्री`,
    btnOK: "Report",
    btnOK_hi: "रिपोर्ट करें",
    btnCancel: "Back",
    btnCancel_hi: "वापस",
  },
  {
    reason: "hatred",
    title: "Hatred and bullying",
    title_hi: "नफरत और बदमाशी",
    dialog_title: "Report as hatred and bullying?",
    dialog_title_hi: "घृणा और धमकाने के रूप में रिपोर्ट करें?",
    dialog_text: `We remove:
                  <br/>- Contents that contain credible threat
                  <br/>- Contents that target people to degrade or shame them
                  <br/>- Personal information shared to blackmail or harass
                  <br/>- Threats to post nude photo of you`,
    dialog_text_hi: `हम हटाते हैं:
                   <br/>- ऐसी सामग्री जिसमें विश्वसनीय खतरा हो
                   <br/>- ऐसी सामग्री जो लोगों को नीचा दिखाने या उन्हें शर्मसार करने के लिए लक्षित करती है
                   <br/>- ब्लैकमेल या परेशान करने के लिए साझा की गई व्यक्तिगत जानकारी
                   <br/>- अपनी न्यूड फोटो पोस्ट करने की धमकी`,
    btnOK: "Report",
    btnOK_hi: "रिपोर्ट करें",
    btnCancel: "Back",
    btnCancel_hi: "वापस",
  },
  {
    reason: "selfharm",
    title: "Self-harm",
    title_hi: "खुद को नुकसान",
    dialog_title: "Report as self-harm?",
    dialog_title_hi: "खुद को नुकसान के रूप में रिपोर्ट करें?",
    dialog_text: `We remove contents encouraging or promoting self injury, which includes suicide, cutting and eating disorders. We may also remove contents identifying victims of self injury if the content attacks or makes fun of them`,
    dialog_text_hi: `हम आत्म-चोट को प्रोत्साहित करने या बढ़ावा देने वाली सामग्री को हटा देते हैं, जिसमें आत्महत्या, काटने और खाने के विकार शामिल हैं। यदि सामग्री हमला करती है या उनका मज़ाक उड़ाती है, तो हम स्वयं को चोट के शिकार लोगों की पहचान करने वाली सामग्री को भी हटा सकते हैं`,
    btnOK: "Report",
    btnOK_hi: "रिपोर्ट करें",
    btnCancel: "Back",
    btnCancel_hi: "वापस",
  },
  {
    reason: "violent-gore",
    title: "Violent, gory and harmful content",
    title_hi: "हिंसक, खूनी और हानिकारक सामग्री",
    dialog_title: "Report as violent, gory and harmful content?",
    dialog_title_hi: "हिंसक, खूनी और हानिकारक सामग्री के रूप में रिपोर्ट करें?",
    dialog_text: `We remove:
                  <br/>- Photos or videos of extreme graphic violence
                  <br/>- Contents that encourage violence or attack anyone based on their religious, ethnic or sexual background
                  <br/>- Specific threats of physical harm, theft, vandalism or financial harm`,
    dialog_text_hi: `हम हटाते हैं:
                     <br/>- अत्यधिक ग्राफिक हिंसा की तस्वीरें या वीडियो
                     <br/>- ऐसी सामग्री जो धार्मिक, जातीय या यौन पृष्ठभूमि के आधार पर हिंसा को प्रोत्साहित करती है या किसी पर हमला करती है
                     <br/>- शारीरिक नुकसान, चोरी, बर्बरता या वित्तीय नुकसान के विशिष्ट खतरे`,
    btnOK: "Report",
    btnOK_hi: "रिपोर्ट करें",
    btnCancel: "Back",
    btnCancel_hi: "वापस",
  },
  {
    reason: "child-porn",
    title: "Child porn",
    title_hi: "चाइल्ड पोर्न",
    dialog_title: "Report as child porn?",
    dialog_title_hi: "चाइल्ड पोर्न के रूप में रिपोर्ट करें?",
    dialog_text: `We remove and may report to legal entity about:
                  <br/>- Photos or videos of sexual intercourse with children
                  <br/>- Contents of nude or partially nude children`,
    dialog_text_hi: `हम हटाते हैं और कानूनी इकाई को इसके बारे में रिपोर्ट कर सकते हैं:
                    <br/>- बच्चों के साथ संभोग की तस्वीरें या वीडियो
                    <br/>- नग्न या आंशिक रूप से नग्न बच्चों की सामग्री`,
    btnOK: "Report",
    btnOK_hi: "रिपोर्ट करें",
    btnCancel: "Back",
    btnCancel_hi: "वापस",
  },
  {
    reason: "illegal-activities",
    title: "Illegal activities (e.g. drug use)",
    title_hi: "अवैध गतिविधियां (जैसे नशीली दवाओं का उपयोग)",
    dialog_title: "Report as illegal activities?",
    dialog_title_hi: "अवैध गतिविधियों के रूप में रिपोर्ट करें?",
    dialog_text: `We remove and may report to legal entity about:
                  <br/>- Contents promoting illegal activities, e.g. the use of hard drugs
                  <br/>- Contents intended to sell or distribute drugs`,
    dialog_text_hi: `हम हटाते हैं और कानूनी इकाई को इसके बारे में रिपोर्ट कर सकते हैं:
                    <br/>- अवैध गतिविधियों को बढ़ावा देने वाली सामग्री, उदा. कठोर दवाओं का उपयोग
                    <br/>- दवाओं को बेचने या वितरित करने के उद्देश्य से सामग्री`,
    btnOK: "Report",
    btnOK_hi: "रिपोर्ट करें",
    btnCancel: "Back",
    btnCancel_hi: "वापस",
  },
  {
    reason: "deceptive-content",
    title: "Deceptive content",
    title_hi: "भ्रामक सामग्री",
    dialog_title: "Report as deceptive content?",
    dialog_title_hi: "भ्रामक सामग्री के रूप में रिपोर्ट करें?",
    dialog_text: `We remove:
                  <br/>- Purposefully fake or deceitful news
                  <br/>- Hoax disproved by a reputable source`,
    dialog_text_hi: `हम हटाते हैं:
                     <br/>- जानबूझकर नकली या धोखेबाज खबर
                     <br/>- होक्स एक प्रतिष्ठित स्रोत द्वारा अस्वीकृत`,
    btnOK: "Report",
    btnOK_hi: "रिपोर्ट करें",
    btnCancel: "Back",
    btnCancel_hi: "वापस",
  },
  {
    reason: "copyright-infringement",
    title: "Copyright and Trademark infringement",
    title_hi: "कॉपीराइट और ट्रेडमार्क उल्लंघन",
    dialog_title: "Report as copyright and trademark infringement?",
    dialog_title_hi: "कॉपीराइट और ट्रेडमार्क उल्लंघन के रूप में रिपोर्ट करें?",
    dialog_text: `We provides an online platform to allows users to upload and share images, videos, and other content. We take the rights of intellectual property owners very seriously and comply as a service provider with all applicable provisions of the United States Digital Millennium Copyright Act.
                  <br/><br/>If you want to report content that you believe violates or infringes your copyright, please tap continue and fill out the Vikarn DMCA Copyright Infringement Notification form. Note that a report alleging infringement or violation of legal rights must come from the rights owner or someone authorized to report on their behalf (e.g. attorney, agent). If you are not the rights owner or their authorized representative, we will not be able to process your report.`,
    dialog_text_hi: `हम उपयोगकर्ताओं को छवियों, वीडियो और अन्य सामग्री को अपलोड और साझा करने की अनुमति देने के लिए एक ऑनलाइन मंच प्रदान करते हैं। हम बौद्धिक संपदा स्वामियों के अधिकारों को बहुत गंभीरता से लेते हैं और एक सेवा प्रदाता के रूप में यूनाइटेड स्टेट्स डिजिटल मिलेनियम कॉपीराइट एक्ट के सभी लागू प्रावधानों का अनुपालन करते हैं।
                    <br/><br/>यदि आप ऐसी सामग्री की रिपोर्ट करना चाहते हैं जो आपको लगता है कि आपके कॉपीराइट का उल्लंघन करती है, तो कृपया जारी रखें टैप करें और विकर्ण DMCA कॉपीराइट उल्लंघन अधिसूचना फॉर्म भरें। ध्यान दें कि कानूनी अधिकारों के उल्लंघन या उल्लंघन का आरोप लगाने वाली रिपोर्ट अधिकार के मालिक या उनकी ओर से रिपोर्ट करने के लिए अधिकृत किसी व्यक्ति (जैसे वकील, एजेंट) से आनी चाहिए। यदि आप अधिकारों के स्वामी या उनके अधिकृत प्रतिनिधि नहीं हैं, तो हम आपकी रिपोर्ट को संसाधित नहीं कर पाएंगे।`,
    btnOK: "Continue",
    btnOK_hi: "जारी रखें...",
    btnCancel: "Back",
    btnCancel_hi: "वापस",
    url1: "https://vikarn.com/ugcp/report/copyright-infringement",
    url: "/help-and-feedback",
  },
  {
    reason: "no-reason",
    title: "I just don't like it",
    title_hi: "मुझे यह पसंद नहीं है",
    dialog_title: "What can I do if I see something I don't like on Vikarn?",
    dialog_title_hi:
      "यदि मैं विकर्ण पर कुछ ऐसा देखता हूँ जो मुझे पसंद नहीं है तो मैं क्या कर सकता हूँ?",
    dialog_text: `<br/>- Report it if it doesn't follow Vikarn's rules
                  <br/>- heart/ unheart it to decide which posts / comments can go viral`,
    dialog_text_hi: `<br/>- अगर यह विकर्ण के नियमों का पालन नहीं करता है तो इसकी रिपोर्ट करें
                     <br/>- यह तय करने के लिए हार्ट/ अनहार्ट करें कि कौन सी पोस्ट/टिप्पणियां वायरल हो सकती हैं`,
    btnOK: "Report",
    btnOK_hi: "रिपोर्ट करें",
    btnCancel: "Back",
    btnCancel_hi: "वापस",
  },
];
